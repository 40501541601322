import React, { useState } from "react"
import H2 from "../../core/H2"
import P from "../../core/P"
import H3 from "../../core/H3"
import GitexPopupModal from "../../core/GitexPopupModal"

export const LeftMediaRightContentBlock = ({
  LeftMediaRightContentBlock,
  colorful,
  theme,
  index
}) => {
  const {
    component: {
      content: {
        align,
        description,
        title,
        tagline,
        tags = [],
        techImages = [],
        logo = {}
      } = {},
      image,
    },
    ctaLink,
    ctaLink2,
    blockWidth,
    mainTitle,
    mainDescription,
    animationColor: { bgColor, textColor },
  } = LeftMediaRightContentBlock
  // const { title: ctaTitle, url } = ctaLink || {}
  const { mediaItemUrl, altText, mediaDetails } = image || {}
  const { width, height } = mediaDetails || {}
  // for logo
  const { mediaItemUrl: logo_mediaItemUrl, altText: logo_altText, mediaDetails: logo_mediaDetails } = logo || {}
  const { width: logo_width, height: logo_height } = logo_mediaDetails || {}
  const fullWidth = blockWidth != null ? blockWidth[0] : null
  //remove after gitex2022 start
  const [openGitex, setOpenGitex] = useState(false)
  const openGitexModal = () => {
    setOpenGitex(true)
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }
  //remove after gitex2022 end
  return (
    <>
      <GitexPopupModal open={openGitex} closeGitexModal={closeGitexModal} />  {/*remove after gitex2022 start*/}
      <div
        className={`${colorful ? "colorful" : ""
          } relative overflow-hidden flex justify-center align-middle ${index === 0 ? "min-h-screen" : ""}`}
        style={{
          backgroundColor: bgColor,
          color: textColor,
        }}
        data-color={bgColor}
        data-text={textColor}
      >
        <div className={`relative py-8 md:py-16 lg:py-20 xl:py-20 flex justify-center align-middle lg:my-auto`}>
          <div className={`w-screen px-4 ${fullWidth ? '' : ' sm:px-6'}`}>
            {mainTitle && (
              <div
                className="flex flex-col place-items-center items-center text-center lg:pb-10  m-4"
              >
                {mainTitle && (
                  <H2
                    title={mainTitle}
                    className={`$ ${textColor} max-w-5xl`}
                    index={index}
                  />
                )}
                {mainDescription && <P title={mainDescription} className={` ${textColor}`} />}
              </div>
            )}
            <div className="lg:grid lg:grid-cols-12 lg:gap-8 lg:max-w-max mx-auto">
              <div
                className={` mt-12 md:mt-20 relative lg:mt-0 ${fullWidth ? "" : "sm:max-w-lg sm:mx-auto lg:max-w-none lg:mx-0"} lg:col-span-6 lg:flex lg:items-center block`}
                data-aos="fade-left"
              >
                <div
                  className={`relative w-full ${fullWidth ? '2xl:px-16' : `${!theme ? "lg:max-w-md mx-auto" : "lg:mx-7 md:mx-7"}`} rounded-lg  flex flex-col justify-center`}
                >
                  <div className="overflow-hidden flex justify-center">
                    <div className={`${fullWidth ? "w-full" : 'h-auto'}`}>
                      {mediaItemUrl && (
                        <img
                          src={mediaItemUrl}
                          alt={altText ? altText : "Technology Stack"}
                          // title={altText ? altText : "Technology Stack"}
                          loading="lazy"
                          height={height}
                          width={width}
                          className={`${fullWidth ? 'w-full' : null}`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`mt-12 md:mt-20 lg:mt-0 text-center ${fullWidth ? "2xl:px-24 px-4 md:px-6 lg:px-12 xl:max-w-6xl  " : ""} lg:max-w-2xl mx-auto lg:col-span-6 flex flex-col justify-center ${align === "Left"
                  ? "lg:text-left md:text-left"
                  : "lg:text-right md:text-right"
                  }`}
                data-aos="fade-right"
              >
                {logo && (<div className="w-full h-auto overflow-hidden flex md:block justify-center md:justify-start mb-6 md:mb-8">
                  <img
                    src={logo_mediaItemUrl}
                    alt={logo_altText ? logo_altText : "Technology Stack"}
                    // title={logo_altText ? logo_altText : "Technology Stack"}
                    loading="lazy"
                    height={logo_height}
                    width={logo_width}
                  />
                </div>)}
                {title && <H2 title={title} className="leading-10" index={index} />}
                {title && theme && (
                  <div className="flex flex-col place-items-center md:place-items-start">
                    <hr
                      className="h-1 w-40 mt-5 border-0"
                      style={{ backgroundColor: theme }}
                    />
                  </div>
                )}
                {tagline && (
                  <div>
                    <H3 title={tagline} className="mt-6 text-shark-500" />
                  </div>
                )}
                {tags && (
                  <p className="mt-6">
                    {tags.map(({ name }) => (
                      <span
                        key={Math.random()}
                        className="text-xs m-1 inline-flex items-center font-normal leading-sm px-4 py-2 bg-transparent  bg-white bg-opacity-10 shadow rounded"
                      >
                        {name}
                      </span>
                    ))}
                  </p>
                )}
                {description && (
                  <P title={description} noCenter className="text-shark-400" />
                )}
                {techImages && (
                  <div className="my-5 grid lg:grid-cols-6 md:grid-cols-6 grid-cols-3 gap-4">
                    {techImages.map(e => (
                      <>
                        {e?.iconUrl?.url ?
                          <a
                            href={e?.iconUrl?.url}
                            key={Math.random()}
                          >
                            <div className="flex justify-center align-middle">
                              <div className="m-3 w-12 h-12 flex justify-center align-middle">
                                {e?.techIcons?.mediaItemUrl && (
                                  <img
                                    height={e?.techIcons?.mediaDetails?.height}
                                    width={e?.techIcons?.mediaDetails?.width}
                                    src={e?.techIcons?.mediaItemUrl}
                                    alt={
                                      e?.techIcons?.altText
                                        ? e?.techIcons?.altText
                                        : "Technology"
                                    }
                                    // title={
                                    //   e?.techIcons?.altText
                                    //     ? e?.techIcons?.altText
                                    //     : "Technology"
                                    // }
                                    loading="lazy"
                                  />
                                )}
                              </div>
                            </div>
                            {e?.techTitle && (
                              <P
                                title={e?.techTitle}
                                className="text-shark-400 text-center font-semibold"
                              />
                            )}
                          </a> :
                          <>
                            <div className="flex justify-center align-middle">
                              <div className="m-3 w-12 h-12 flex justify-center align-middle">
                                {e?.techIcons?.mediaItemUrl && (
                                  <img
                                    height={e?.techIcons?.mediaDetails?.height}
                                    width={e?.techIcons?.mediaDetails?.width}
                                    src={e?.techIcons?.mediaItemUrl}
                                    alt={
                                      e?.techIcons?.altText
                                        ? e?.techIcons?.altText
                                        : "Technology"
                                    }
                                    // title={
                                    //   e?.techIcons?.altText
                                    //     ? e?.techIcons?.altText
                                    //     : "Technology"
                                    // }
                                    loading="lazy"
                                  />
                                )}
                              </div>
                            </div>
                            {e?.techTitle && (
                              <P
                                title={e?.techTitle}
                                className="text-shark-400 text-center font-semibold"
                              />
                            )}
                          </>
                        }
                      </>

                    ))}
                  </div>
                )}
                {ctaLink && (
                  <div
                    className={`mt-10 md:inline-flex justify-center  ${align === "Left" ? "lg:justify-start" : "lg:justify-end"
                      }`}
                  >
                    {
                      ctaLink.url.charAt(0) === "#" && ctaLink?.url?.length === 1 ?
                        <button
                          className="inline-flex bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded border border-transparent"
                          onClick={openGitexModal} //remove after gitex2022
                        >
                          {ctaLink?.title}
                        </button>
                        :
                        <div className="inline-flex rounded-full ">
                          <a
                            href={ctaLink.url}
                            className="inline-flex bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded border border-transparent"
                            target={ctaLink.target}
                          >
                            {ctaLink.title}
                          </a>
                        </div>
                    }

                    {ctaLink2 && (
                      <div className="md:ml-3 mt-5 md:mt-0 inline-flex rounded-full ">
                        <a
                          href={ctaLink2.url}
                          className="inline-flex bg-transparent relative px-8 py-3 text-base leading-6 font-medium text-blue-500 hover:text-blue-600 rounded border border-blue-500 hover:border-blue-600"
                          target={ctaLink2.target}
                        >
                          {ctaLink2.title}
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeftMediaRightContentBlock
